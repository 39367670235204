import React from "react";
import { Link, navigate } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginForm from "./loginForm";
import RegisterForm from "./registerForm";
import { login, register, getUser } from "../../utils/auth";
// import { isUserSubscribed } from "../../utils/revenueCat";
import "./style.scss";
 
const JoinPage = ({ location }) => {
  const clickedLogin =
    location && location.search && location.search === "?login";

  console.log(location.search);

  const [state, setState] = React.useState({
    isAuthenticated: false,
    isAuthenticating: true,
    wantsToRegister: !clickedLogin,
    email: "",
    password: "",
    errorMessage: "",
  });

  React.useEffect(() => {
    if (location.search === "?login") {
      setState({ ...state, wantsToRegister: false });
    } else if (location.search === "?register") {
      setState({ ...state, wantsToRegister: true });
    }
  }, [location]);

  console.log(state.wantsToRegister);

  React.useEffect(() => {
    redirectIfAuth();
  }, []);

  const redirectIfAuth = async () => {
    try {
      const user = await getUser();
      setState({ ...state, isAuthenticated: true, isAuthenticating: false });
      // const subscription = await isUserSubscribed(user.username);
      // if (subscription){
      //     navigate(`/manage-subscription`);
      // }else {
      navigate(`/subscription`);
      // }
    } catch (e) {
      setState({ ...state, isAuthenticated: false, isAuthenticating: true });
      console.log("Not logged in");
      console.log(e);
    }
  };

  const toggleForm = () => {
    setState({
      ...state,
      wantsToRegister: !state.wantsToRegister,
      errorMessage: "",
    });
  };

  const toggleFormName = () => (state.wantsToRegister ? "Log in" : "Register");

  const onRegister = async (formData) => {
    try {
      await register(formData);
      await redirectIfAuth();
    } catch (error) {
      if (error.code === "UsernameExistsException") {
        setState({
          ...state,
          errorMessage: "This user already exists, please login.",
        });
        return false;
      }
      if (error.code === "InvalidParameterException") {
        if (error.message.includes("password")) {
          console.log(error.code);
          setState({
            ...state,
            errorMessage: "The password needs to have 6 or more characters",
          });
          return false;
        }
        console.log(error.code);
        setState({ ...state, errorMessage: error.message });
        return false;
      }
      if (error.code === "UserLambdaValidationException") {
        console.log(error.code);
        setState({ ...state, errorMessage: "You must provide all fields" });
        return false;
      }
      setState({
        ...state,
        errorMessage: "The registration could not be processed.",
      });
      console.log(error);
    }
  };

  const onLogin = async (formData) => {
    try {
      await login(formData);
      await redirectIfAuth();
    } catch (e) {
      console.log(e);
      if (
        e.code === "UserNotFoundException" ||
        e.code === "NotAuthorizedException"
      ) {
        setState({
          ...state,
          errorMessage: "Incorrect email/password combination",
        });
        return false;
      }
    }
  };

  return (
    <Layout navbarSettings={{ showMenu: false }}>
      <SEO title="Join" />
      <Container fluid className="join-page">
        <Row>
          <Col className="left-col">
            <Col className="text-center">
              <div className="form-box">
                <p className="text-center error-message">
                  {state.errorMessage}
                </p>
                {!state.isAuthenticated && !state.wantsToRegister && (
                  <LoginForm onLogin={onLogin} />
                )}
                {!state.isAuthenticated && state.wantsToRegister && (
                  <RegisterForm onRegister={onRegister} />
                )}

                {!state.isAuthenticated && !state.wantsToRegister && (
                  <>
                    <p>
                      Forgot password?{" "}
                      <Link className="toggle-btn" to="/forgot-my-password">
                        Reset
                      </Link>
                    </p>
                    <p>
                      Don't have an account?{" "}
                      <a className="toggle-btn" onClick={toggleForm}>
                        {toggleFormName()}
                      </a>
                    </p>
                  </>
                )}
                {!state.isAuthenticated && state.wantsToRegister && (
                  <p>
                    Already have an account?{" "}
                    <a className="toggle-btn" onClick={toggleForm}>
                      {toggleFormName()}
                    </a>
                  </p>
                )}
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default JoinPage;
